import { render, staticRenderFns } from "./settlementTypes.vue?vue&type=template&id=246e4a4c&scoped=true&"
import script from "./settlementTypes.vue?vue&type=script&lang=js&"
export * from "./settlementTypes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246e4a4c",
  null
  
)

export default component.exports