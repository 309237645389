<template>
  <div>
    <settlement-types></settlement-types>
  </div>
</template>

<script>
import SettlementTypes from "@/components/admin/subclassifiers/settlementTypes";
export default {
  name: "settlementsTypesView",
  components: { SettlementTypes },
};
</script>

<style scoped></style>
