<template>
  <div>
    <h2>Тип населённых пунктов</h2>
    <el-table stripe border :data="settlementTypes" style="width: 100%">
      <el-table-column prop="name" label="Наименование"> </el-table-column>
      <el-table-column width="450" label="">
        <template slot-scope="raw">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                @click="viewItem(raw.row)"
                style="float: right"
                type="primary"
                >Просмотреть</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="settlementTypes.size"
      :current-page.sync="settlementTypes.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="settlementTypes.totalElements"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "settlementTypes",
  data() {
    return {
      pageOptions: {
        page: 0,
        size: 20,
      },
    };
  },

  computed: {
    ...mapGetters({
      settlementTypes: "GETLISTTYPES",
    }),
  },

  methods: {
    handleSizeChange(val) {
      this.getAreaList({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAreaList({ page: val - 1, size: this.settlementTypes.size });
    },

    viewItem(item) {
      console.log(item);
      this.$router.push(this.$route.params.districtId + "/type/" + item.id);
    },

    getSettlementTypesList(data) {
      this.$store.dispatch("getListAllSettlementTypes", data);
    },
  },

  mounted() {
    this.getSettlementTypesList({
      areaId: this.$route.params.areaId,
      districtId: this.$route.params.districtId,
      page: 0,
      size: 20,
    });
  },
};
</script>

<style scoped></style>
